import { stAnalytics } from "@repo/analytics";
import { A, useLocation } from "@solidjs/router";
import { TbMinusVertical } from "solid-icons/tb";
import { type Component, For, Show, createSignal } from "solid-js";
import { twMerge } from "tailwind-merge";
import Logo from "~/assets/Logo";
import { UserAvatarWithMenu } from "~/components/UserAvatarWithMenu";
import { StButton } from "~/components/buttons";
import { StIcon } from "~/components/icons";
import { NewThreadIdIndicator } from "~/domains/threads";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";
import { SmartchatBanner } from "./SmartchatBanner";

export const LandingPageNavbar: Component<{ class?: string }> = (props) => {
  const wire = useWire();
  const location = useLocation();

  const [active, setActive] = createSignal("Product");
  const links = [
    {
      label: "Enterprise-Grade",
      link: "https://web.storytell.ai",
    },
    {
      label: "Pricing",
      link: "https://web.storytell.ai/pricing",
    },
    {
      label: "Docs",
      link: "https://docs.storytell.ai",
    },
  ];

  const workLifePlay = [
    {
      label: "Work",
      link: "/work",
    },
    {
      label: "Life",
      link: "/life",
    },
    {
      label: "Play",
      link: "/play",
    },
  ];

  return (
    <header
      class={twMerge("absolute inset-x-0 top-0 z-50 text-white", props.class)}
    >
      <SmartchatBanner />
      <nav
        class="flex items-center justify-between p-6 lg:px-8 max-w-screen-xl mx-auto relative h-[88px]"
        aria-label="Global"
      >
        <div class="flex lg:flex-1">
          <a href="/" class="-m-1.5 p-1.5">
            <Logo />
          </a>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <div class="flex gap-x-3 items-center">
            <For each={workLifePlay}>
              {(link, index) => (
                <>
                  <A class="relative" href={link.link}>
                    {link.label}

                    <Show when={location.pathname.startsWith(link.link)}>
                      <div class="absolute bottom-0 -left-1 -right-1 bg-white h-[4px] top-[53px] rounded-full z-10" />
                    </Show>
                  </A>
                  <Show when={index() !== workLifePlay.length - 1}>
                    <StIcon
                      class="size-4 mt-[2px] text-violet-400"
                      icon={TbMinusVertical}
                    />
                  </Show>
                </>
              )}
            </For>
          </div>

          <For each={links}>
            {(link) => (
              <a
                href={link.link}
                onClick={() => setActive(link.label)}
                class="relative text-sm tracking-wider font-medium leading-6"
              >
                {link.label}
                <Show when={active() === link.label}>
                  <div class="absolute bottom-0 -left-1 -right-1 bg-white h-[4px] top-[53px] rounded-full z-10" />
                </Show>
              </a>
            )}
          </For>
        </div>
        <div class="flex flex-1 justify-end items-center">
          <Show
            when={
              wire.services.identity.snapshot.context.identity
                .isAuthenticated &&
              !wire.services.identity.snapshot.context.identity.isGuest
            }
            fallback={
              <>
                <A
                  href="/auth/login"
                  onClick={() => {
                    stAnalytics.track("log_in_clicked", undefined);
                  }}
                  class="text-sm font-semibold leading-6 mr-6 underline underline-offset-2 whitespace-pre"
                >
                  Log in
                </A>

                <StButton
                  href="/auth/signup"
                  onClick={() => {
                    stAnalytics.track("sign_up_clicked", undefined);
                  }}
                  class="px-5 dark:bg-white hover:bg-violet-100 dark:hover:bg-violet-100 dark:text-indigo-900 whitespace-pre text-nowrap"
                  size="lg"
                >
                  Sign up
                </StButton>
              </>
            }
          >
            <>
              <A
                href={urls.thread(
                  wire.services.identity.snapshot.context.identity
                    .workingContext.projectId,
                  NewThreadIdIndicator,
                )}
                class="text-sm font-semibold leading-6 text-gray-100 mr-6 underline underline-offset-2"
              >
                Dashboard
              </A>

              <UserAvatarWithMenu />
            </>
          </Show>
        </div>

        <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-r h-[2px] from-transparent via-violet-300/20 to-transparent" />
      </nav>
    </header>
  );
};
